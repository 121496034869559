import React, { FunctionComponent } from 'react';

interface Props {
}

const DefaultForm: FunctionComponent<Props> = (props) => {
    return (
        <>
            DefaultForm
        </>
    );
};

export default DefaultForm;
