import React, { FunctionComponent } from 'react';

interface Props {
}

const WorkForm: FunctionComponent<Props> = (props) => {
    return (
        <>
            WorkForm
        </>
    );
};

export default WorkForm;
